import React,{FC, Dispatch, useEffect} from 'react'
import DropdownBranch from '../../../../../../general_components/DropdownBranch'
import DatePickerRange from '../../../../../../general_components/DatePickerRange'
import DropdownEmployee from '../../../../../../general_components/DropdownEmployee'
import { Filters } from '../../../types'

type Props = {
    mainClassName: string;
    filters: Filters;
    setFilters: Dispatch<React.SetStateAction<Filters>>
}

const FiltersSection: FC<Props> = ({ mainClassName, filters, setFilters }) => {

    const handleFilters = (e: any) => {
        if(!e) return null
        const concatenatedEmployeeIds = e.map((item: { sellerId: string }) => item.sellerId).join(','); 
        return concatenatedEmployeeIds;
    }

    return (
        <div className={mainClassName}>
            <h6>Opciones de Filtrado</h6>
            <div id="filter-area">
                <DropdownBranch
                    onValueChange={(e) => { 
                        setFilters({ ...filters, branch: e }) 
                    }}
                />
                <DropdownEmployee
                    onValueChange={(e) => { 
                        setFilters({ ...filters, employees: handleFilters(e) }) 
                    }}
                    departments={[1]}
                    branch={filters.branch}
                    onlySeellers
                />
                <DatePickerRange
                    onRangeChange={(e) => { 
                        setFilters({ ...filters, from: e?.from, to: e?.to }) 
                    }}
                />
            </div>

        </div>
    )
}

export default FiltersSection