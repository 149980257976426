/* eslint-disable react-hooks/exhaustive-deps */
// useSeguimientoSurtido.js
import { useEffect, useState, useRef } from 'react';
import { formatDate } from '../../../helpers/formatDate';

const useSeguimientoSurtido = (URL) => {

    const [timelineChartData, setTimelineChartData] = useState(null);
    const [estatusSurtido, setEstatusSurtido] = useState(null);
    const [filters, setFilters] = useState({ sucursal: null, status: null, pedido: null });
    const [stackedData, setStackedData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [lastPercent, setLastPercent] = useState(0)

    const getDescription = (idsurtidoestatus) => {
        const estatusObj = estatusSurtido.find(obj => obj.idsurtidoestatus === idsurtidoestatus);
        return estatusObj ? estatusObj.descripcion : 'Descripci�n no encontrada';
    };
    const getSeverity = (idsurtidoestatus) => {
        const estatusObj = estatusSurtido.find(obj => obj.idsurtidoestatus === idsurtidoestatus);
        return estatusObj ? estatusObj.severity : 'Descripci�n no encontrada';
    };
    const getId = (descripcion) => {
        const estatusObj = estatusSurtido.find(obj => obj.descripcion === descripcion);
        return estatusObj ? estatusObj.idsurtidoestatus : 'id no encontrado';
    };
    const getEstatues = () => {
        return estatusSurtido.map(obj => obj.descripcion);
    };

    const colors = {
        ideal: { borderColor: 'red', borderDash: [5, 5], borderWidth: 3, pointRadius: 0},
        lmm: { borderColor: 'rgba(128, 0, 128, 0.2)', borderWidth: 3,pointRadius: 1 },
        gve: { borderColor: 'rgba(54, 124, 43, 0.2)', borderWidth: 3, pointRadius: 1 },
        gml: { borderColor: 'rgba(0, 0, 255, 0.2)', borderWidth: 3, pointRadius: 1 },
    };

    const cities = [
        { label: 'Ideal', key: 'ideal' },
        { label: 'Los Mochis', key: 'lmm', id: 1 },
        { label: 'Guasave', key: 'gve', id:2 },
        { label: 'Guamuchil', key: 'gml', id: 3}
    ];

    const createDatasets = (data, cities) => {
        return cities.map(city => ({
            label: city.label,
            data: data.map(item => item[city.key]),
            borderColor: colors[city.key].borderColor,
            backgroundColor: 'transparent',
            borderWidth: colors[city.key].borderWidth,
            pointRadius: colors[city.key].pointRadius,
            tension: 0.3,
            ...(colors[city.key].borderDash && { borderDash: colors[city.key].borderDash }),
        }));
    };

    useEffect(() => {
        if (!filters.sucursal) return;
    
        fetch(URL + 'gtmprthstry?branch=' + filters.sucursal)
            .then(response => response.json())
            .then(data => {
                const filteredDatasets = createDatasets(data, cities).filter(dataset => dataset.data.some(value => value !== null && value !== undefined && value !== 0));

                const chartData = {
                    labels: data.map(item => formatDate(item.date)),
                    datasets: filteredDatasets
                };
                setTimelineChartData(chartData);
                 // Calcular el último valor de acuerdo a la sucursal seleccionada
                if (filters.sucursal === -1) { // Todas las sucursales
                    const lastValues = filteredDatasets
                        .map(data => data[data.length - 1]) // Toma el último valor del arreglo reducido
                        .filter(value => value != null); // Excluye valores null y undefined

                    console.log("Últimos valores excluyendo el primero de cada arreglo:", lastValues);

                    const total = lastValues.slice(1).reduce((acc, val) => acc + val, 0);
                    const promedio = lastValues.length > 0 ? total / lastValues.length : 0;

                    console.log("Promedio calculado:", promedio);
                    setLastPercent(promedio.toFixed(2));
                }
                else { // Sucursal específica
                const selectedCity = cities.find(city => city.id === filters.sucursal);
                if (selectedCity) {
                    const selectedDataset = filteredDatasets.find(dataset => dataset.label === selectedCity.label);
                    if (selectedDataset) {
                        const lastValue = selectedDataset.data[selectedDataset.data.length - 1];
                        setLastPercent(lastValue || 0);
                    } else {
                        setLastPercent(0);
                    }
                } else {
                    setLastPercent(0);
                }
            }
            })
            .catch(error => {
                console.error('Error fetching import history data:', error);
            });
    }, [URL, filters.sucursal]);

    useEffect(() => {
        if (filters.sucursal != null && filters.status != null && filters.pedido != null)
            listData();

    }, [URL,filters])
    
    useEffect(() => {
        fetch(URL + 'getEstatusSurtido')
            .then(response => response.json())
            .then(data => {
                setEstatusSurtido(data);
            })
            .catch(error => {
                console.error('Error fetching surtido inmediato data:', error);
            });
    }, [URL]);

    const listData = () => {
        const url = `${URL}getSurtidoInmediato?sucursal=${filters.sucursal}&status=${filters.status}&pedido=${filters.pedido}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                const dataWithIndex = data.map((element, index) => {
                    return {
                        ...element,
                        index: index + 1
                    };
                });
                // me quede en el stackeddata falta pasarlo al componente para contar cada status
                setStackedData(data.reduce((accumulator, element) => {
                    const estatus = element.estatus;

                    if (estatus === 0) {
                        accumulator.na++;
                    } else if (estatus === 1) {
                        accumulator.pedidos++;
                    } else if (estatus === 2) {
                        accumulator.omitidos++;
                    } else if (estatus === 3) {
                        accumulator.pc++;
                    }

                    return accumulator;
                }, { na: 0, pedidos: 0, omitidos: 0, pc: 0 }))
                setTableData(dataWithIndex);
            })
            .catch(error => {
                console.error('Error fetching surtido inmediato data:', error);
            });
    }

    const actualizarPiezaSurtida = (id, numeroEstatus, comentario) => {

        const data = {
            id,
            numeroEstatus,
            comentario
        };

        fetch(URL + 'updatepiezasurtida', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.status === 200) {
                } else {
                    console.error('Error en la solicitud:', response.status);
                }
            })
            .catch(error => {
                console.error('Error en la solicitud:', error);
            });
        //listData();
    };

    const handleFilterChangeAndFetchData = (value, identifier) => {
        switch (identifier) {
            case 'sucursal':

                setFilters({
                    ...filters,
                    sucursal: value
                });
                break;
            case 'estatus':
                setFilters({
                    ...filters,
                    status: value
                });
                break;
            case 'pedido':
                setFilters({
                    ...filters,
                    pedido: value
                });;
                break;
            default:
                break;
        }
    };

    return {
        timelineChartData,
        tableData,
        getSeverity,
        setTableData,
        actualizarPiezaSurtida,
        getDescription,
        getEstatues,
        getId,
        handleFilterChangeAndFetchData,
        filters,
        stackedData,
        lastPercent
        // Add more data and functions as needed
    };
};

export default useSeguimientoSurtido;
