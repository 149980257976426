


export const validateFilters = (filters: object, callback?: any) => {
    const isValid = Object.values(filters).every(propValue => propValue !== null && (!Array.isArray(propValue) || propValue.length > 0))
    if (isValid) {
        callback();
    }
    return isValid
}

export const validateEntries = (filters: object, callback?: any) => {
    const invalidEntries: string[] = [];
    const fieldTranslations: { [key: string]: string } = {
        'branch': 'Sucursal',
        'department': 'Departamento',
        'employee': 'Colaborador',
        'concept': 'Concepto',
        'date': 'Fecha de Plantación',
        'markers': 'Plantar árboles (En el mapa)',
        'images': 'Subir Fotos',
    };

    let isValid = true;

    for (const key in filters) {
        //@ts-ignore
        const propValue = filters[key];
        let translatedKey = key;

        // Buscar traducción del nombre del campo
        if (fieldTranslations[key]) {
            translatedKey = fieldTranslations[key];
        }

        const isInvalid = (
            propValue === null ||
            propValue === -1 ||
            (typeof propValue === 'string' && propValue.trim() === '') ||
            (Array.isArray(propValue) && propValue.length === 0)
        );

        if (isInvalid) {
            isValid = false;
            invalidEntries.push(translatedKey);
        }
    }

    if (isValid && callback) {
        callback();
    }

    return {
        isValid,
        invalidEntries
    };
}



export const getRandomDarkColor = () => {
    const darkColors = [
        'darkblue',
        'darkred',
        'darkgreen',
        'darkpurple',
        'darkorange',
        'darkpink',
        'darkcyan',
        'darkmagenta',
        'darkviolet',
        'darkslategray',
        'darkolivegreen',
        'darkgoldenrod',
        'darkturquoise',
        'darkorchid',
        'darkseagreen',
        'darkkhaki',
        'darkslateblue',
        'darkgray',
        'darkslategrey',
        'darkbrown',
    ];

    const randomIndex = Math.floor(Math.random() * darkColors.length);
    return darkColors[randomIndex];
}


export const calculatePercentage = (partialAmount: any, totalAmount: any) => {
    // Validate that both amounts are numbers
    if (typeof partialAmount !== 'number' || typeof totalAmount !== 'number') {
        console.warn('Both amounts must be numbers');
    }

    // Validate that the total amount is not zero to avoid division by zero
    if (totalAmount === 0) {
        console.warn('The total amount cannot be zero');
    }

    // Calculate the percentage and round to two decimals
    const percentage = (partialAmount / totalAmount) * 100;
    const roundedPercentage = parseFloat(percentage.toFixed(2));

    return roundedPercentage < 0 ? 0 : roundedPercentage;
};
