import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { GetEmployees } from '../request/index';

interface DropdownEmployeeProps {
    branch: number | null;
    departments: number[];
    onlySeellers?: boolean;
    onValueChange: (value: any) => void;
}
// (props: any)
const DropdownEmployee: React.FC<DropdownEmployeeProps> = ({ branch, departments, onValueChange, onlySeellers }) => {

    const [selectedEmployees, setSelectedEmployees] = useState<any>(null);
    const [employeesList, setEmployeesList] = useState<any>(null);

    useEffect(() => {
        const initilize = async () => {
            const employeesFetched = await GetEmployees(branch, departments, onlySeellers ?? false);
            setEmployeesList(employeesFetched);
            setSelectedEmployees(null);
            onValueChange(null);
        };
        initilize();
    }, [branch]);

    useEffect(() => {
        customizeMultiselect();
    }, [employeesList]);

    const customizeMultiselect = () => {
        const multiselectTokenLabels = document.querySelectorAll('.p-multiselect-token-label');

        multiselectTokenLabels.forEach((label) => {
            const palabras = label.textContent?.split(' ') || [];
            const ultimaPalabra = palabras[palabras.length - 1];
            if (ultimaPalabra) {
                label.textContent = ultimaPalabra;
            }
        });

        const multiselectContainer = document.querySelector('.p-multiselect');
        const multiselectLabel = multiselectContainer?.querySelector('.p-multiselect-label');

        if (multiselectLabel) {
            //@ts-ignore
            multiselectLabel.style.padding = '0.2rem';
            //@ts-ignore
            multiselectLabel.style.marginLeft = '0.5rem';
            //@ts-ignore
            multiselectLabel.style.zIndex = 1;
        }

        const chips = document.querySelectorAll('.p-multiselect-token');
        chips.forEach(chip => {
            //@ts-ignore
            chip.style.backgroundColor = '#2196F3';
            //@ts-ignore
            chip.style.color = '#fff';
            //@ts-ignore
            chip.style.height = '22px';
        });
    };

    return (
        <span className="p-float-label mt-20 panel-element">
            <MultiSelect
                inputId="dd-employees"
                value={selectedEmployees}
                optionLabel="name"
                optionGroupLabel="label" 
                optionGroupChildren="items"
                onChange={(e) => { 
                    setSelectedEmployees(e.value ); 
                    onValueChange(e.value == '' ? null : e.value);
                }}
                options={employeesList}
                placeholder="Colaboradores"
                className="dropdown"
                scrollHeight='500px'
                maxSelectedLabels={1}
                selectedItemsLabel="{0} Colaboradores"
                
            />
            <label htmlFor="dd-employees">Colaboradores</label>
        </span>
    );
};

export default DropdownEmployee;
